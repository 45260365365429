<script lang="ts">
	export let height = '48px' // Default height
	export let width = '48px' // Default width

	let className = ''
	export { className as class }
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
>
	<path
		id="Subtract"
		fill="currentColor"
		fill-rule="evenodd"
		d="M5.838857142857143 19.018285714285714a18.161142857142856 18.161142857142856 0 1 1 36.32228571428571 0c0 6.034285714285714 -2.742857142857143 11.633142857142856 -5.9074285714285715 16.10742857142857 -3.1817142857142855 4.494857142857143 -6.908571428571428 8.029714285714286 -9.150857142857143 9.973714285714285a4.714285714285714 4.714285714285714 0 0 1 -6.2057142857142855 0c-2.2422857142857144 -1.9439999999999997 -5.969142857142857 -5.478857142857143 -9.150857142857143 -9.977142857142857 -3.1645714285714286 -4.470857142857143 -5.9074285714285715 -10.069714285714285 -5.9074285714285715 -16.104Zm24.21257142857143 -0.9668571428571427c0 3.8742857142857137 -2.177142857142857 6.054857142857142 -6.051428571428571 6.054857142857142s-6.051428571428571 -2.1805714285714286 -6.051428571428571 -6.054857142857142S20.125714285714285 12 24 12s6.051428571428571 2.1805714285714286 6.051428571428571 6.051428571428571Z"
		clip-rule="evenodd"
		stroke-width="3.4285714285714284"
	/></svg
>
